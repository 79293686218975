import React, { useCallback } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroVideoSection from "../components/hero-video"
import ProductInfo from "../components/product/product-info"
import ProductSummary from "@/components/product/product-summary"
import LegalAdvice from "@/components/product/legal-advice"
import { gtag_report_conversion } from '../lib/utils';

function ProductTemplate({ pageContext: { product } }) {
  const category = product.SubCategoryId.CategoryId;
  const subcategory = product.SubCategoryId;
  const fullUrl = `https://liderancaegestao.online/${category.Slug}/${subcategory.Slug}/${product.Slug}`;
  const ldJson = [];
  const breadcrumbs = [
    { name: category.Name, link: `/${category.Slug}` },
    { name: subcategory.Name, link: `/${category.Slug}/${subcategory.Slug}` },
    { name: product.Name, link: `/${category.Slug}/${subcategory.Slug}/${product.Slug}` }
  ];

  const productLd = {
    "@context": "https://schema.org",
    "@type": "Book",
    "name": product.Name,
    "genre": `${category.Name}/${subcategory.Name}`,
    "abstract": product.MetadataDescription,
    "isbn": product.Code,
    "author": {
      "@type": "Person",
      "name": product.Author[0].Author_Id.Name,
      "sameAs": product.Author[0].Author_Id.PersonalPageLink
    },
  };
  ldJson.push(productLd);

  const trackAmazonClick = useCallback((productLd, listName) => {
    let item = {
      item_id: productLd.isbn,
      item_name: productLd.name,
      coupon: '',
      discount: 0,
      affiliation: 'Amazon',
      item_brand: productLd.author.name,
      item_category: productLd.genre,
      currency: 'BRL',
      quantity: 1,
      item_list_name: listName
    };

    if (!window.gtag)
      return;

    window.gtag('event', 'begin_checkout', {
      "currency": "BRL",
      "value": 0.01,
      "coupon": "",
      "items": [
        item
      ],
    });

    gtag_report_conversion(undefined, 0.01);

    console.info("click tracked");
  }, []);

  const breadcrumbsLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbs.map((item, index) => {
      return {
        "@type": "ListItem",
        "position": index + 1,
        "name": item.name,
        "item": `https://liderancaegestao.online${item.link}`
      }
    })
  }
  ldJson.push(breadcrumbsLd);

  if (product.VideoLink !== null && product.VideoLink !== "") {
    const videoLd = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": `Review do livro ${product.Name}`,
      "description": product.MetaDescription,
      "embedUrl": product.VideoLink,
    }

    ldJson.push(videoLd);
  }
  return (
    <Layout>
      <SEO title={product.Name} ldJson={ldJson} description={product.MetaDescription} keywords={product.MetaKeywords} contentType="book" image={product.OriginalImageUrl} />
      <div className="flex flex-col min-h-screen font-sans">
        <main className="flex-1 bg-white">
          <HeroVideoSection videoTitle={product.VideoTitle} videoLink={product.VideoLink} name={product.Name} />
          <div className="container mx-auto px-4 md:px-6">
            <div className="max-w-6xl mx-auto">
              {/* <Breadcrumbs items={breadcrumbs} /> */}
            </div>
          </div>

          <ProductInfo product={product} trackEvent={() => trackAmazonClick(productLd, 'Review')} />
          <ProductSummary product={product} />
          <LegalAdvice bookName={product.Name} bookLink={product.Link} />
        </main>
      </div>
    </Layout>
  )

}

function AuthorList(props) {
  if (props == null || props.authors == null)
    return null;

  return (
    <div className="pt-10">
      <h2>Autor</h2>
      <hr />
      {props.authors.map(author => <Author key={author.Author_Id.Name} author={author.Author_Id} />)}
    </div>
  );
}
function Author(props) {
  return (
    <h4><Link to={`/autor/${props.author.Slug}/`}>{props.author.Name}</Link></h4>
  )
}

export default ProductTemplate