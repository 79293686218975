import React from "react"
import ReactMarkdownWithHtml from "react-markdown"
import { Button } from "@/components/ui/button"
import { BookOpen, Share2 } from 'lucide-react'
import { shareCallback } from "../../lib/utils"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function ProductSummary({ product }) {
  const category = product.SubCategoryId.CategoryId;
  const subcategory = product.SubCategoryId;


  const fullUrl = `https://liderancaegestao.online/${category.Slug}/${subcategory.Slug}/${product.Slug}`;
  const shareData = {
    title: product.Name,
    url: fullUrl,
    text: "Liderança e Gestão Online."
  }
  return (
    <section className="w-full py-12">
      <div className="container mx-auto px-4 md:px-6">
        <div className="max-w-6xl mx-auto prose prose-lg">
          <article className="text-gray-700">
            <h2>Resumo do Livro</h2>
            <ReactMarkdownWithHtml children={product.Description} />
          </article>
          <div className="flex flex-wrap gap-4">
            <OutboundLink href={product.Link} target="_blank" rel="noopener">
              <Button size="lg" className="gap-2 text-base font-semibold">
                <BookOpen className="h-5 w-5" />
                Comprar
              </Button>
            </OutboundLink>
            <Button onClick={async () => await shareCallback(shareData)} variant="outline" size="lg" className="gap-2 text-base font-semibold">
              <Share2 className="h-5 w-5" />
              Compartilhar
            </Button>
          </div>
        </div>
      </div>
    </section>

  );
}